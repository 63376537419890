// Here you can add other styles
body{
  font-family: Noto Sans, sans-serif;
}

.card, .card-header, .card-body {
  border-radius: 0 !important;
}

.btn-white {
  font-style: normal;
  font-weight: normal;
  color: rgba(44,44,44,1);
  background-color: rgba(232,239,247,1);
}

.btn-primary{
  background-color: rgba(112,202,242,1);
  border:none ;
}

.card .card-header{
  text-align: left;
  background-color: rgba(188,201,214,1);
  font-style: normal;
  font-weight: bold;
  color: rgba(44,44,44,1);
}

.card .card-subtitle{
  font-style: normal;
  font-weight: bold;
  color: rgba(63,121,173,1);
}
.card .card-text {
  font-style: normal;
  font-weight: normal;
  color: rgba(114,114,114,1);
}

.card .card-body{
  text-align: left;
}

// START Explorer

.text-choose-layout {
  font-style: normal;
  font-weight: bold;
  color: rgba(114,114,114,1);
}

.layout-change-button {
  background: none;
  padding: 0;

}

.btn-explorer-action {
  background: none;
  border: none;

}

.file-ops-container {
  flex-direction: row;
  justify-content: space-between;
}

.btn-explorer-action :hover {
  border: 1px;
  background: none;
}

.table-fix-head {
  overflow-y: auto;
  height: 100px;
}

.table-fix-head thead th {
  position: sticky;
  top: 0;
  z-index: 2;
  border-top: none;
  border-bottom: 1px solid #c8ced3;
}

.table-fix-head th {
  background: #ffffff;
}

.animate-fade-in {
  -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.5s; /* Firefox < 16 */
  -ms-animation: fadein 0.5s; /* Internet Explorer */
  -o-animation: fadein 0.5s; /* Opera < 12.1 */
  animation: fadein 0.5s;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

// END Explorer

// START REMOTE CONFIG

.btn-blue {
  background-color: rgba(63,121,173,1);
  color: #ffffff;
}

.btn-blue :hover{
  background-color: rgba(63,121,173,1);
  color: #ffffff;

}

.btn-no-background {
  background: none;
  border: none;
  font-weight: bold;
  color: rgba(44,44,44,1);
}
.timeline {

  padding-top: 20px;
  padding-bottom: 20px;

  h4 {
    padding-top: 10px;
    font-weight: bold;
    color: rgba(140,140,140,1);

  }

  .btn-step-count {
    border-radius: 100px;
    background: none;
    border: 5px solid rgba(140, 140, 140, 1);
    color: rgba(66, 66, 66, 1);
    font-weight: bold;

  }

  .timeline-divider {
    border: 2px solid rgba(66,66,66,1);
    margin-top: 24px;

  }

  .step-active{
    .btn {
      border: 5px solid rgba(63,121,173,1);
    }
    h4 {
      color: rgba(66,66,66,1);
    }
  }
  .btn-step-active {
    border: 5px solid rgba(63,121,173,1);
  }

}


// Timeline code

// END Remote Config

// START Sidebar

.sidebar{
  ul.nav {
    background-color: #ffffff;
    font-family: Nunito, sans-serif;
    font-weight: bold;
  }
  .nav-link.active {
    background-color: #ffffff;
    color: rgb(63,121,173);
  }
  .nav-link.active > .nav-icon :hover{
    color: rgb(63,121,173);
  }
  .nav-link {
    color: rgb(114,114,114);
    padding-top: 15px;
    padding-bottom: 15px;
    border-top: 1px solid rgba(232, 239, 247, 1);
  }

  .nav-link-success:hover {
    color: #fff;
  }
}

// END Sidebar

.custom-tab {
  border-right: 1px solid #c8ced3;
  background-color: #ffffff;
  color: #000000;
  overflow-x: hidden;

  i {
    color: #000;
  }
}

.tab-active {
  background: #1b8eb7;
  color: #ffffff;

  i {
    color: #fff;
  }
}

.text-red {
  color: #FF0000;
}

.pointer-cursor {
  cursor: pointer;
}

.progress-cursor {
  cursor: progress;
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 100%;

  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 100%;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 3;
  height: 300px;
  overflow: auto;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.progress-modal {
  position: fixed;
  bottom: 0;
  margin-right: 5px;
  margin-bottom: 5px;
  right: 0;
  font-size: small;
  max-width: 25vw;
}

.progress-modal-body {
  max-height: 30vh;
}

.task-modal {
  position: fixed;
  width: 600px;
  top: 50%;
  left: 50%;
  margin-top: -100px; /* Negative half of height. */
  margin-left: -250px; /* Negative half of width. */
}

.image-modal {
  margin-left: 10vw;

}

.image-modal .modal-content {
  margin: 0;
  width: 80vw;
  background: transparent;
  border: none;
}

.rotatableImage {
  transform-origin: top left;
  /* IE 10+, Firefox, etc. */
  -webkit-transform-origin: top left;
  /* Chrome */
  -ms-transform-origin: top left;
  /* IE 9 */
}

.rotate90 {
  transform: rotate(90deg) translateY(-100%);
  -webkit-transform: rotate(90deg) translateY(-100%);
  -ms-transform: rotate(90deg) translateY(-100%);
}

.rotate180 {
  transform: rotate(180deg) translate(-100%, -100%);
  -webkit-transform: rotate(180deg) translate(-100%, -100%);
  -ms-transform: rotate(180deg) translateX(-100%, -100%);
}

.rotate270 {
  transform: rotate(270deg) translateX(-100%);
  -webkit-transform: rotate(270deg) translateX(-100%);
  -ms-transform: rotate(270deg) translateX(-100%);
}

.upload-box {
  height: 150px;
  border: 2px darkgray dashed;
  background: rgba(211, 211, 211, 0.23);
  color: rgb(101, 101, 101);
}

